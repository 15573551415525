import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy'
import * as Sentry from "@sentry/vue"
import no from "../../lang/no.json";
import sv from "../../lang/sv.json";
import {createI18n} from "vue-i18n";

const appName = import.meta.env.VITE_APP_NAME || 'Molendo';
// production / development
const mode = import.meta.env.MODE

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        let locale = 'no'

        if(props.initialPage.props.locale) {
            // Get locale from HandleInertiaRequest middleware
            locale = props.initialPage.props.locale
        } else if(localStorage.hasOwnProperty('locale')) {
            locale = localStorage.getItem('locale')
        } else if(navigator.languages.includes('no')) {
            locale = 'no'
        }else if(navigator.languages.includes('sv')) {
            locale = 'sv'
        }

        const i18n = createI18n({
            locale,
            fallbackLocale: 'no',
            messages: Object.assign({no, sv}),
            legacy: false // must be false with Composition API
        })

        const app = createApp({ render: () => h(App, props) });

        if(mode === 'production') {
            Sentry.init({
                app,
                dsn: "https://02920807b4971d8c0d75dae4de5b711a@o4506506935205888.ingest.sentry.io/4506506966663168",
                mode,
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: ["localhost", 'https://molendo.com', 'http://molendo.local'],
                    }),
                    new Sentry.Replay({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }

        app.use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(i18n)
            .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
